
$primary: #26A65B;
$secondary:  rgb(51, 104, 73);
$dark:  #111111;
$info: #1f6d30;

// $theme-colors: (
//     "primary": $primary,
//     "secondary": $secondary
// );

$min-contrast-ratio: 2.5;

@import "~bootstrap/scss/bootstrap.scss";

h6 {
    font-size: x-small;
}

.btn {
    border-radius: 4%;
}

.scroll-shadows {
    // animation: pulse 5s both;

    background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .5), rgba(255, 255, 255, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
    background-position: left center, right center, left 8%, right 8%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 15px 100%, 15px 100%, 15px 202px, 15px 202px;
    background-attachment: local, local, scroll, scroll;
  }


  .is21 .modal-content {
    background: white;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }

//   @keyframes pulse {
//     0% {
//         background-image: linear-gradient(to right, white, white),
//         linear-gradient(to right, white, white),
//         radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0)),
//         radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0));
//     }
//     100% {
//         background-image: linear-gradient(to right, white, white),
//         linear-gradient(to right, white, white),
//         radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .6), rgba(255, 255, 255, 0)),
//         radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0));
//     }
//   }